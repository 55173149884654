var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"card-body"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.messagelist}},[_c('el-table-column',{attrs:{"prop":"id","label":_vm.$t('system.message.index'),"width":"75"}}),_c('el-table-column',{attrs:{"label":_vm.$t('system.message.type'),"width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm.$t("global.notice.type." + scope.row.type)))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('system.message.body')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                    scope.row.data.action && scope.row.data.type2 == 'device'
                  )?_c('span',[_vm._v(" "+_vm._s(_vm.$t("global.notice.device", { device: scope.row.data.edid, }) + " " + _vm.$t("global.notice." + scope.row.data.type1))+" ")]):_vm._e(),(
                    scope.row.data.action && scope.row.data.type2 == 'service'
                  )?_c('span',[_vm._v(" "+_vm._s(_vm.$t("global.notice.service", { service: _vm.$t( "global.notice.server." + scope.row.data.service ), }) + " " + _vm.$t("global.notice." + scope.row.data.type1))+" ")]):_vm._e(),(
                    scope.row.data.action && scope.row.data.type2 == 'stock'
                  )?_c('span',[_vm._v(" "+_vm._s(_vm.$t("global.notice.stock", { edid: scope.row.data.edid, stock: scope.row.data.stock, }))+" ")]):_vm._e(),(
                    scope.row.data.action && scope.row.data.type2 == 'sensor'
                  )?_c('span',[_vm._v(" "+_vm._s(_vm.$t("global.notice.sensor", { edid: scope.row.data.edid, num: scope.row.data.num, }))+" ")]):_c('span',[_vm._v(" "+_vm._s(scope.row.data.post)+" "+_vm._s(scope.row.data.receive)+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"time","label":_vm.$t('system.message.time'),"width":"180"}})],1),_c('div',{staticClass:"text-center",staticStyle:{"margin-top":"15px"}},[_c('el-pagination',{attrs:{"current-page":_vm.page,"page-sizes":[10, 20, 50, 100],"page-size":_vm.limit,"layout":"sizes, prev, pager, next","total":_vm.messagetotal,"hide-on-single-page":true},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.getmessagelist,"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}})],1)],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }